import * as React from 'react';
import './BottomBar.css'

const BottomBar = () => {
    return (
        <div className='BottomBarLayer'>
            <div className='BottomBarView'>
                <div className='BottomBarContent'>
                    <div className='BottomBarDescriptionContainer'>
                        <div className='BottomBarDescriptionContent'>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BottomBar